import './App.css';
import React from 'react';
import CollapsibleSection from './CollapsibleSection';
import linkedInLogo from './linkedinLogo.svg';
import githubLogo from './githubLogo.svg';
import linkedInLogoRed from './linkedInLogo--red.svg';
import githubLogoRed from './githubLogo--red.svg';

const  App = () => {

  const [ collapsedExperience, setCollapsedExperience ] = React.useState( {
    deepsurface: false,
    verticalchange: true,
    citrix: true,
    rightsignature: true,
    elc: true,
  } );

  const isCollapsed = sectionKey => collapsedExperience[sectionKey] === true;

  const toggleCollapse = sectionKey => {
    let _collapsed = { ...collapsedExperience };

    if ( _collapsed[sectionKey] === true ) {
      _collapsed = {
        ..._collapsed,
        [sectionKey]: false,
      };
    } else {
      _collapsed = {
        ..._collapsed,
        [sectionKey]: true,
      };
    }
    setCollapsedExperience( _collapsed );
  }

  return (
    <div className="App">
      <div className="content">
        <section className="intro">
          <div className="left">
            <h1>Dan Caccavano</h1>
            <div className="leftBar">
              <div className="color1" />
              <div className="color2" />
              <div className="color3" />
              <div className="color4" />
            </div>
            <div className="social">
              <a
                href="https://www.linkedin.com/in/dcaccavano/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ linkedInLogo } alt="LinkedIn" />
              </a>
              <a
                href="https://github.com/dcaccavano"
                target="_blank"
                rel="noopener noreferrer"
                >
                  <img src={ githubLogo } alt="GitHub" />
              </a>
            </div>
          </div>
          <div className="right">
            <div className="rightBar" />
            <p>
              I am a <strong>front end software engineer </strong> and I have been
              developing applications for over <strong>13 years. </strong>
              I have worked at large 10,000+ employee
              enterprise software companies, as well as scrappy startups.
              I love working on interesting projects and building amazing applications.
            </p>
            <p>
              I am also the father of two amazing daughters, I live in Portland, OR,
              and when I am not coding, I love woodworking, doing arts and crafts,
              having adventures with the kiddos, listening to my records,
              playing board games, and hiking.
            </p>
            <p>
              I have extensive experience using
              <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer" className="ml-1 text-main hover:underline">
                Reactjs
              </a>
              ,
              <a href="https://rubyonrails.org/" target="_blank" rel="noopener noreferrer" className="ml-1 text-main hover:underline">
                Ruby on Rails
              </a>
              ,
              <a href="https://emberjs.com/" target="_blank" rel="noopener noreferrer" className="ml-1 text-main hover:underline">
                Emberjs
              </a>
              ,
              <a href="https://tailwindcss.com/" target="_blank" rel="noopener noreferrer" className="ml-1 text-main hover:underline">
                tailwindcss
              </a>
              , and
              <a href="https://webpack.js.org/" target="_blank" rel="noopener noreferrer" className="ml-1 text-main hover:underline">
                Webpack
              </a>
              .
            </p>
          </div>
        </section>
        <section className="experience">
          <div className="left">
            <h2>Experience</h2>
          </div>
          <div className="right">
            <div className="rightBar" />
            <CollapsibleSection
              isCollapsed={ isCollapsed }
              toggleCollapse={ toggleCollapse }
              sectionKey="deepsurface"
              header={
                <React.Fragment>
                  <strong>Lead Software Engineer: </strong> DeepSurface <strong>(July 2020 - Present)</strong>
                </React.Fragment>
              }
              content={
                <React.Fragment>
                  <p>
                    At DeepSurface, we build innovative and groundbreaking cyber security software that helps users to
                    visualize their real world risk and remediate potential issues before they can cause problems.
                    My projects have included:
                  </p>
                  <ul>
                    <li>Completely re-writing the entire front end codebase from scratch in Reactjs</li>
                    <li>Designing and implementing the user experience and user interface</li>
                    <li>Building a complete charting and visualization solution from scratch that renders highly customizable svg visuals</li>
                    <li>Building out a complete drag and drop report creator with dozens of customizable widgets, all from scratch</li>
                    <li>Implementing a comprehensive caching layer into the front end to avoid unnecessary fetches and duplication of data</li>
                  </ul>
                </React.Fragment>
              }
            />
            <CollapsibleSection
              isCollapsed={ isCollapsed }
              toggleCollapse={ toggleCollapse }
              sectionKey="verticalchange"
              header={
                <React.Fragment>
                  <strong>Sr. Software Engineer: </strong> VerticalChange <strong>(July2017 - June 2020)</strong>
                </React.Fragment>
              }
              content={
                <React.Fragment>
                  <p>
                    At VerticalChange, we built ambitious software solutions for nonprofits and government agencies. Our
                    clients used our off the shelf software as well as custom tailored experiences just for them. While
                    there, my projects included:
                  </p>
                  <ul>
                    <li>Implementing a complete overhaul of our UI using css-modules and custom designed components</li>
                    <li>Helping to update our stack to use webpack and react in lieu of a legacy angular framework</li>
                    <li>Building a complete attendance tracking solution from the ground up with the capability of tracking thousands of early childhood students</li>
                    <li>Updating and enhancing nearly every facet of our product from our form builder to our reports and contact tracking systems</li>
                  </ul>
                </React.Fragment>
              }
            />
            <CollapsibleSection
              isCollapsed={ isCollapsed }
              toggleCollapse={ toggleCollapse }
              sectionKey="citrix"
              header={
                <React.Fragment>
                  <strong>Sr. Software Engineer: </strong> Citrix <strong>(October 2014 - July 2017)</strong>
                </React.Fragment>
              }
              content={
                <React.Fragment>
                  <p>
                    At Citrix, I was brought in as part of an acquisition of RightSignature, an online document signing
                    application. While at Citrix I worked heavily on:
                  </p>
                  <ul>
                    <li>Integrating the RightSignature experience into different workflows and applications within Citrix</li>
                    <li>Completely overhauling the UI of RightSignature to better adhere to the rest of the Citrix software ecosystem using Emberjs and Sass</li>
                  </ul>
                </React.Fragment>
              }
            />
            <CollapsibleSection
              isCollapsed={ isCollapsed }
              toggleCollapse={ toggleCollapse }
              sectionKey="rightsignature"
              header={
                <React.Fragment>
                  <strong>Software Engineer: </strong> RightSignature <strong>(April 2013 - October 2014)</strong>
                </React.Fragment>
              }
              content={
                <React.Fragment>
                  <p>
                    At RightSignature we built an ambitious product that allowed people to fill out and sign documents
                    online. We were the underdog in a growing market but we regularly punched way above our weight as a
                    small development team. Some notable projects included:
                  </p>
                  <ul>
                    <li>Building a separate application from the ground up with the latest (at the time) Angularjs concepts</li>
                    <li>Helping to rebuild our form building experience to make it quicker and easier to turn a physical form into a digital one using advanced techniques, such as automatically detecting where form fields needed to be placed.</li>
                  </ul>
                </React.Fragment>
              }
            />
            <CollapsibleSection
              isCollapsed={ isCollapsed }
              toggleCollapse={ toggleCollapse }
              sectionKey="elc"
              header={
                <React.Fragment>
                  <strong>Software Engineer: </strong> ELC Technologies <strong>(June 2011 - April 2013)</strong>
                </React.Fragment>
              }
              content={
                <React.Fragment>
                  <p>
                    ELC was a consultancy where we worked on vastly different projects for different clients. Engagements
                    lasted from a couple of months to several years. I got my start at ELC  and while there, I worked on
                    several key projects including a social networking platform for ESPN used by thousands, a platform
                    for up and coming filmmakers called JuntoBox Films, an online exercise platform for crossfit
                    enthusiasts, and many other internal and external projects.
                  </p>
                </React.Fragment>
              }
            />
          </div>
        </section>
        <section className="education">
          <div className="left">
            <h2>Education</h2>
          </div>
          <div className="right">
            <div className="rightBar" />
            <ul>
              <li><strong>Master of Arts in Teaching: </strong> University of Portland <strong>(2007)</strong></li>
              <li><strong>BA in English Literature: </strong> University of Portland <strong>(2005)</strong></li>
            </ul>
          </div>
        </section>
        <section className="contact">
          <div className="left">
            <h2>Contact</h2>
          </div>
          <div className="right">
            <div className="rightBar" />
            <form name="contact" method="POST" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
              />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email address"
              />
              <textarea
                id="message"
                name="message"
                placeholder="Message"
              />
              <div className="formActions">
                <div className="social">
                  <a
                    href="https://www.linkedin.com/in/dcaccavano/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ linkedInLogoRed } alt="LinkedIn" />
                  </a>
                  <a
                    href="https://github.com/dcaccavano"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                      <img src={ githubLogoRed } alt="GitHub" />
                  </a>
                </div>
                <button type="submit">Send</button>
              </div>

            </form>
          </div>
        </section>
      </div>

    </div>
  );
}

export default App;
