import React from 'react';

import carretUp from './carretUp.svg';
import './CollapsibleSection.css';

const CollapsibleSection = ( {
  sectionKey,
  isCollapsed,
  toggleCollapse,
  header,
  content,
} ) => {
  return (
    <React.Fragment>
      {
        ( content && header ) &&
        <div
          className={ `collapsibleSectionContainer ${isCollapsed( sectionKey ) ? 'collapsed' : ''}` }
        >
          <div className="collapsibleSectionHeader" onClick={ () => toggleCollapse( sectionKey ) }>
            <span>
              { header }
            </span>
            <img src={ carretUp } alt="carretUp" className="carretUp" />
          </div>
          <div className="collapsibleSectionContent">
            { content }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default CollapsibleSection;